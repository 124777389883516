<template>
  <b-overlay :show="loading" rounded="sm">

    <div v-if="order">
      <div class="d-flex align-items-center justify-content-between mb-1">
        <div class="d-flex">
          <b-button
            variant="primary"
            class="btn-icon mr-2"
            @click="() => $router.go(-1)"
          >
            <feather-icon icon="ArrowLeftIcon" />
          </b-button>
          <h3>
            Detalle de orden #{{ order.code }}
            <b-badge pill :variant="getColorStatus(order.order_latest_status.code)" class="badge-glow">
              {{ order.order_latest_status.name }}
            </b-badge>
          </h3>
        </div>

        <div class="text-right">
          <h2 class="text-primary">
            Total de la orden: {{ formatCurrency(order.total_more_extras) }}
          </h2>
        </div>

      </div>

      <div class="d-flex justify-content-between">
        <div>
          <h4>{{ dateFormat(order.created_at, 'DD MMMM YYYY, h:m:ss a') }}</h4>
          <h4>Repartidor asignado: {{ order.distributor ? order.distributor.user.full_name : 'Sin repartidor asignado' }}</h4>
          <h4 v-if="order.vehicle">Vehículo: {{ order.vehicle }}</h4>
          <h4>Factura electronica: {{ order.electronic_invoice ? 'Si' : 'No' }}</h4>
          <h4 v-if="order.electronic_invoice && order.converted">Factura electronica generada</h4>
        </div>
        <div>
          <h4>Dirección de recogida: {{ order.pickup_address }}</h4>
          <h4>Dirección de entrega: {{ order.delivery_address }}</h4>
        </div>
      </div>

      <hr>

      <div class="d-flex justify-content-between">
        <div>
          <h4>Deuda en mensajeria: {{ formatCurrency(order.due.due_services) }}</h4>
          <h4>Deuda en compras: {{ formatCurrency(order.due.due_addons) }}</h4>
          <h4>Deuda total: {{ formatCurrency(order.due.due_total) }}</h4>
        </div>
        <div class="text-right">
          <h4>Total en mensajeria: {{ formatCurrency(order.total) }}</h4>
          <h4>Total en extras: {{ formatCurrency(order.additional_total) }}</h4>
          <h4>Total pagado en mensajeria: {{ formatCurrency(order.payment_services) }}</h4>
          <h4>Total pagado en extras: {{ formatCurrency(order.payment_additionals) }}</h4>
        </div>
      </div>

      <hr>

      <div class="row">
        <div class="col">

          <b-card title="Descripción de la orden" >
            <b-card-text>
              {{ order.description }}
            </b-card-text>
          </b-card>

          <b-card
            title="Servicios"
            class="mb-4"
          >
            <b-card-text>
              <table class="table">
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Descripción</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(service, i) of order.services" :key="i">
                    <td>{{ service.service.name }}</td>
                    <td>{{ service.service.description || 'S/D' }}</td>
                    <td>{{ formatCurrency(service.price) }}</td>
                  </tr>
                  <tr>
                    <td colspan="3">
                      <p class="text-right">
                        <b>Total:</b> {{ formatCurrency(order.total) }}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-card-text>
          </b-card>

          <b-card
            title="Extras"
            class="mb-4"
          >
            <b-card-text>
              <table class="table">
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Precio</th>
                    <th>Deposito</th>
                    <th>Caja chica</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(addon, i) of order.additional_features" :key="i">
                    <td>
                      {{ addon.name }}
                    </td>
                    <td>{{ formatCurrency(addon.price) }}</td>
                    <td>{{ formatCurrency(addon.deposit) }}</td>
                    <td>
                      <b v-if="addon.petty_cash">{{ formatCurrency(addon.expense_petty_cash) }}</b>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="4">
                      <p class="text-right">
                        <b>Total:</b> {{ formatCurrency(order.additional_total) }}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-card-text>
          </b-card>

          <b-card
            title="Pagos"
            class="mb-4"
          >
            <b-card-text>
              <table v-if="order.payments.length" class="table">
                <thead>
                  <tr>
                    <th>Fecha</th>
                    <th>Método de pago</th>
                    <th>Monto</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(pay, i) of order.payments" :key="i">
                    <td>{{ dateFormat(pay.created_at, 'DD MMMM YYYY, h:m:ss a') }}</td>
                    <td>{{ pay.payment_method.name }}</td>
                    <td>{{ formatCurrency(pay.amount) }}</td>
                  </tr>
                </tbody>
              </table>
              <p v-else class="text-center">Sin pagos</p>
            </b-card-text>
          </b-card>

          <b-card
            title="Fotos"
            class="mb-4"
          >
            <b-card-text>
              <div class="row">
                <div class="col-lg-6" v-for="(photo, i) of order.photos" :key="i">
                  <b-card
                    :img-src="photo.url_photo"
                    img-top
                    img-alt="card img"
                    class="mb-3"
                  >
                    <b-card-text>
                      {{ photo.description }}
                    </b-card-text>
                  </b-card>
                </div>
              </div>
            </b-card-text>
          </b-card>

        </div>
        <div class="col">
          <div class="row">
            <div class="col">
              <b-card
                title="Cliente"
                class="mb-4"
              >
                <b-card-text>
                  <p class="font-weight-bold">
                    <router-link :to="`/client-detail/${order.client.id}`">
                      {{ order.client.full_name }}
                    </router-link>
                  </p>
                  <hr>
                  <p>Identificación: {{ order.client.identification_number }}</p>
                  <p>Dirección: {{ order.client.address }}</p>
                  <p>Teléfono: {{ order.client.phone }}</p>
                  <p>Correo: {{ order.client.email }}</p>
                </b-card-text>
              </b-card>
            </div>
            <div class="col">
              <b-card
                title="Notas"
                class="mb-4"
              >
                <b-card-text>
                  <vue-perfect-scrollbar
                    v-if="order.notes.length"
                    ref="refChatLogPS"
                    :settings="perfectScrollbarSettings"
                    class="user-chats scroll-area"
                  >
                    <b-list-group flush>
                      <b-list-group-item
                        v-for="note in order.notes"
                        :key="note.id"
                      >
                        {{ note.note }}
                      </b-list-group-item>
                    </b-list-group>
                  </vue-perfect-scrollbar>
                  <p v-else class="text-center">Sin notas</p>
                </b-card-text>
              </b-card>
            </div>
          </div>

          <b-card
            title="Estados"
            class="mb-4"
          >
            <b-card-text>
              <app-timeline>
                <app-timeline-item
                  v-for="(status, i) of order.order_statuses"
                  :key="i"
                  :title="status.status.name"
                  :subtitle="status.motive"
                  :time="dateFormat(status.created_at, 'DD/MM/YYYY, hh:mm')"
                  variant="primary"
                />
              </app-timeline>
            </b-card-text>
          </b-card>

        </div>
      </div>

    </div>

  </b-overlay>
</template>

<script>
import { onMounted, ref } from '@vue/composition-api'
import moment from 'moment'
moment.locale('es')

import {
  currencyFormat
} from '@/helpers'

import orderService from '@/services/order.service'

import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {
  BBadge,
  BCard,
  BCardText,
  BRow,
  BCol,
  BOverlay,
  BButton,
  BListGroup,
  BListGroupItem
} from 'bootstrap-vue'

export default {
  components: {
    BBadge, BCard, BCardText, BRow, BCol, BOverlay, BButton, BListGroup, BListGroupItem, AppTimeline,
    AppTimelineItem, VuePerfectScrollbar
  },
  setup(props, { root }) {

    const perfectScrollbarSettings = {
      maxScrollbarLength: 300,
    }
    const loading = ref(false)
    const order = ref(null)

    /*methods*/

    const getOrderDetail = async id => {
      loading.value = true
      const { data: res } = await orderService.getOrderDetail(id)
      order.value = res.data
      loading.value = false
    }

    const dateFormat = (date, format = 'DD/MM/YYYY') => {
      return moment(date).format(format)
    }

    const formatCurrency = amount => {
      return currencyFormat(amount)
    }

    const getColorStatus = (code_status) => {
      if (code_status == 'in_process') return 'info'
      if (code_status == 'in_route') return 'warning'
      if (code_status == 'in_destiny') return 'warning'
      if (code_status == 'delivered') return 'success'
      if (code_status == 'finished') return 'primary'
      if (code_status == 'cancelled') return 'danger'
    }

    onMounted(() => {
      const param = root.$route.params.id

      getOrderDetail(param)

    })

    return {
      getColorStatus,
      formatCurrency,
      loading,
      order,
      dateFormat,
      perfectScrollbarSettings
    }
  },
}
</script>

